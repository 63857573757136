<template>
  <div class="menu">
    <router-link to="/">
      <div class="logo-wrap">
        <img src="/logo.png" class="logo" />
        <p>一秒·私人云服务</p>
      </div>
    </router-link>
    <div class="menu-wrap">
      <p class="header">服务专区</p>
      <router-link class="menu-item spider" to="/main/spider">
        <span class="menu-title">个人爬虫</span>
      </router-link>
      <div class="menu-item store disabled" @click="showTip">
        <span class="menu-title">私密存储</span
        ><el-tag type="danger" size="mini">暂未开放</el-tag>
      </div>
      <div class="menu-item gpt disabled" @click="showTip">
        <span class="menu-title">GPT</span
        ><el-tag type="danger" size="mini">暂未开放</el-tag>
      </div>
      <div class="menu-item ai disabled" @click="showTip">
        <span class="menu-title">AIGC服务</span
        ><el-tag type="danger" size="mini">暂未开放</el-tag>
      </div>
      <div class="menu-item bot disabled" @click="showTip">
        <span class="menu-title">QQ机器人</span>
        <el-tag type="danger" size="mini">暂未开放</el-tag>
      </div>
      <div class="menu-item wiki disabled" @click="showTip">
        <span class="menu-title">知识库接口</span>
        <el-tag type="danger" size="mini">暂未开放</el-tag>
      </div>
    </div>
    <p class="close" @click="hideSideMenu">关闭菜单</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    showTip() {
      this.$message.warning("该功能暂未开放");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.menu {
  padding-top: 20px;
  position: relative;
  a {
    text-decoration: none;
    color: inherit;
  }
  .close {
    display: none;
  }
  .logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
    img {
      height: 30px;
      margin-right: 10px;
    }
  }
  .menu-wrap {
    padding: 24px;
    .header {
      color: #170f49;
      font-size: 12px;
      padding-left: 16px;
    }
    .menu-item {
      margin-top: 10px;
      line-height: 38px;
      padding-left: 32px;
      padding-right: 12px;
      height: 38px;
      color: #170f49;
      border-radius: 4px;
      cursor: pointer;
      background-position: 10px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
      display: block;
      text-decoration: none;
      display: flex;
      align-items: center;
      .menu-title {
        flex: auto;
      }
      &.disabled {
        color: #666;
        &:hover,
        &.router-link-active {
          color: #666;
          background-color: #f5f5f5;
        }
        &.spider:hover {
          background-image: url("../assets/images/spider.png");
        }
        &.ai:hover {
          background-image: url("../assets/images/ai.png");
        }
        &.store:hover {
          background-image: url("../assets/images/store.png");
        }
        &.bot:hover {
          background-image: url("../assets/images/bot.png");
        }
        &.gpt:hover {
          background-image: url("../assets/images/gpt.png");
        }
        &.wiki:hover {
          background-image: url("../assets/images/wiki.png");
        }
      }

      &:hover,
      &.router-link-active {
        color: #fff;
        background-color: $theme-color;
      }
      &.spider {
        background-image: url("../assets/images/spider.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/spider_white.png");
        }
      }
      &.ai {
        background-image: url("../assets/images/ai.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/ai_white.png");
        }
      }
      &.store {
        background-image: url("../assets/images/store.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/store_white.png");
        }
      }
      &.gpt {
        background-image: url("../assets/images/gpt.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/gpt_white.png");
        }
      }
      &.bot {
        background-image: url("../assets/images/bot.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/bot_white.png");
        }
      }
      &.wiki {
        background-image: url("../assets/images/wiki.png");
        &:hover,
        &.router-link-active {
          background-image: url("../assets/images/wiki_white.png");
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .menu {
    height: 100%;
    .menu-wrap {
      padding: 10px;
    }
    .close {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 20px;
      height: 30px;
      line-height: 30px;
      color: #666;
      text-align: center;
    }
  }
}
</style>
