<template>
  <div class="app">
    <div class="app-left">
      <Menu />
    </div>
    <van-popup
      :value="sideMenuShow"
      position="left"
      :close-on-click-overlay="false"
      :style="{
        height: '100vh',
      }"
    >
      <Menu />
    </van-popup>
    <div class="app-main">
      <div class="app-header">
        <AppHeader />
      </div>
      <div class="app-inner">
        <router-view />
      </div>
    </div>
    <PayDialog />
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import AppHeader from "../components/Header.vue";
import PayDialog from "../components/PayDialog.vue";
export default {
  name: "main-app",
  components: {
    Menu,
    AppHeader,
    PayDialog,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/var";
.app {
  height: 100vh;
  overflow: hidden;
  display: flex;
  .van-popup {
    display: none;
  }
  .app-left {
    height: 100%;
    width: 240px;
    border-right: 1px solid #d9dbe9;
    flex: none;
  }
  .app-main {
    flex: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .app-header {
      height: 72px;
      border-bottom: 1px solid #d9dbe9;
      flex: none;
    }
    .app-inner {
      flex: auto;
      overflow-y: auto;
    }
  }
}

@media (max-width: 700px) {
  .app {
    .van-popup {
      display: block;
    }
    .app-left {
      display: none;
    }
  }
}
</style>
