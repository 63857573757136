<template>
  <div class="pay-content">
    <template v-if="step == 0">
      <h3 class="section-header">
        {{ userinfo.expiredate ? "续订" : "开通" }}服务
      </h3>
      <p class="section-intro">
        请按需{{ userinfo.expiredate ? "续订" : "开通" }}需要的服务时长
      </p>
      <div class="package-wrap">
        <div
          :key="item.pid"
          v-for="(item, index) in packages"
          class="package-item"
          :class="{ active: pid == item.pid }"
          @click="choosePackage(index)"
        >
          <p class="name">{{ item.name }}</p>
          <p class="price-original">{{ item.originalPrice }}元</p>
          <p class="price">￥{{ item.price }}元</p>
          <img
            class="label"
            src="https://res.oodcd.cn/pan/icons/hot.png"
            v-if="item.label"
          />
        </div>
      </div>
      <h4 class="feature-header">服务权益:</h4>
      <div class="feature-wrap">
        <div class="feature-item">
          <p class="title">可视化操作</p>
          <p class="intro">无需编写代码，操作简单</p>
        </div>
        <div class="feature-item">
          <p class="title">操作简单</p>
          <p class="intro">无需编写代码就能采集任意网站</p>
        </div>
        <div class="feature-item">
          <p class="title">安全稳定</p>
          <p class="intro">私有化部署、加密传输等方式保障数据安全</p>
        </div>
        <div class="feature-item">
          <p class="title">保护隐私</p>
          <p class="intro">保护隐私，使用过程畅快无忧</p>
        </div>
        <div class="feature-item">
          <p class="title">场景丰富</p>
          <p class="intro">零门槛，适用于=版权监测、个人学习...</p>
        </div>
        <div class="feature-item">
          <p class="title">全自动云采集</p>
          <p class="intro">定时采集更新，自动智能识别网页数据</p>
        </div>
      </div>
      <div class="section-footer">
        <p>
          {{ userinfo.expiredate ? "续订" : "开通" }}即同意
          <router-link to="/">《会员协议》</router-link>
        </p>
        <div class="right">
          确认支付：￥{{ currentPackage.price }}
          <el-button type="primary" @click="createOrder">{{
            userinfo.expiredate ? "确认续订" : "立即开通"
          }}</el-button>
        </div>
      </div>
    </template>

    <template v-if="step == 1">
      <h4 style="text-align: center; margin-bottom: 30px; font-size: 20px">
        扫码支付
      </h4>
      <div class="qrcode-wrap">
        <img
          :src="qrcode"
          @error="qrcodeError = true"
          class="qrcode"
          alt="支付二维码加载失败"
        />
        <p
          class="tip"
          v-if="paytype == 'wxpay' && !qrcodeExpired && !qrcodeError"
        >
          <van-icon name="wechat" class="wxpay" />请用微信扫码扫码支付
        </p>
        <p class="tip danger" v-if="!qrcodeExpired && !qrcodeError">
          支付结果返回前请勿关闭当前窗口
        </p>

        <p class="tip danger" v-if="qrcodeExpired" @click="retry">
          订单已超时，点击重新创建订单 <van-icon name="replay" />
        </p>
      </div>

      <div class="button-wrap">
        <el-button @click="retry">重新选择套餐</el-button>
        <el-button type="primary" @click="confirmOrder">我已支付</el-button>
      </div>
    </template>

    <template v-if="step == 2">
      <div class="success-wrap">
        <lottie-player
          class="gift-animation"
          src="https://res.oodcd.cn/pan/lottie/gift.json"
          background="transparent"
          speed="0.8"
          autoplay
        />
        <p class="tip">💐充值成功</p>
      </div>
    </template>
  </div>
</template>

<script>
import { getPayPackages, queryOrder, createOrder } from "@/api/pay";

export default {
  data() {
    return {
      step: 0,
      pid: "",
      currentPackage: {},
      packages: [],
      paytype: "wxpay",
      qrcodeError: false,
      qrcodeExpired: false,
      qrcodeTimer: null,
      order_id: "",
      payurl: "",
      qrcode: "",
      channel: "official",
    };
  },
  async created() {
    const packages = await getPayPackages();
    this.packages = packages;
    this.choosePackage(0);
  },
  destroyed() {
    this.qrcodeTimer && clearInterval(this.qrcodeTimer);
  },
  methods: {
    choosePackage(index) {
      this.currentPackage = this.packages[index];
      this.pid = this.currentPackage.pid;
    },
    async createOrder() {
      this.$toast.loading("正在调起支付窗口");

      let paytype = this.paytype;
      if (!this.isMobile && this.paytype == "wxpay") {
        this.channel = "official";
      }

      if (this.isMobile && this.isWeixin) {
        paytype = "jsapi";
      }

      const res = await createOrder({
        pid: this.pid,
        plt: this.isMobile ? "mobile" : "pc",
        client: this.client,
        paytype: paytype,
      });

      if (res.code == 0) {
        this.step = 1;
        const { order_id, payurl, qrcode } = res.data;
        this.order_id = order_id;
        this.qrcode = qrcode;
        this.payurl = payurl;

        if (this.isWeixin || paytype == "jsapi") {
          return (window.location.href = payurl);
        }

        // 查询订单支付状态
        let queryTimes = 0;
        this.qrcodeTimer = setInterval(async () => {
          const res = await queryOrder({
            order_id: this.order_id,
            type: this.channel,
          });
          if (res.code == 0) {
            await this.paySuccess();
          } else {
            queryTimes++;
            if (queryTimes > 60) {
              this.qrcodeExpired = true;
              this.qrcodeTimer && clearInterval(this.qrcodeTimer);
            }
          }
        }, 1500);
      } else {
        this.$toast.fail("支付渠道申请中...");
      }
    },
    async confirmOrder() {
      const res = await queryOrder({
        order_id: this.order_id,
        type: this.channel,
      });
      if (res.code == 0) {
        await this.paySuccess();
      } else if (res.code == 10302) {
        return this.$toast.fail("检测到尚未支付");
      } else if (res.code == 10304) {
        return this.$toast.fail("支付平台出错，请联系网站管理员");
      } else if (res.code == 10303) {
        return this.$toast.fail("订单已过期");
      } else {
        this.$toast.fail("出现错误，请稍后重试");
      }
    },
    retry() {
      this.step = 0;
      this.order_id = "";
      this.qrcode = "";
      this.payurl = "";
      this.qrcodeExpired = false;
      this.qrcodeTimer && clearInterval(this.qrcodeTimer);
    },
    async paySuccess() {
      this.step = 2;
      await this.updateUserinfo();
      this.qrcodeTimer && clearInterval(this.qrcodeTimer);
    },
    handleConfirmPay() {
      this.$router.replace({ name: "search" });
      setTimeout(() => {
        this.$toast("欢迎回来，您是搜索站相关贡献用户！");
      }, 500);
    },
    handleBack() {
      window.history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_var.scss";
.pay-content {
  max-width: 880px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
  &.content-in-dialog {
    .package-wrap {
      .package-item {
        .name {
          font-size: 20px;
        }
        .price {
          font-size: 20px;
        }
      }
    }
  }
  h3 {
    font-size: 24px;
  }
  .section-intro {
    color: #514f6e;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  .package-wrap {
    display: flex;
    justify-content: space-between;
    .package-item {
      width: 180px;
      max-width: 24%;
      padding: 24px;
      border-radius: 16px;
      border: 2px solid #ddd;
      text-decoration: none;
      color: #666;
      font-size: 13px;
      transition: all ease 0.2s;
      position: relative;
      .label {
        position: absolute;
        width: 80px;
        top: 0px;
        right: 0px;
      }
      .name {
        font-size: 22px;
        font-weight: bold;
      }
      .price-original {
        font-size: 16px;
        text-decoration: line-through;
        color: #666;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &:hover,
      &.active {
        cursor: pointer;
        border-color: $theme-color;
        color: $theme-color;
      }
      .price {
        font-size: 24px;
        font-weight: bold;
      }
      .tip {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px dashed #eee;
        .num {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  }

  .feature-header {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  .section-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    font-weight: bold;
    .right {
      display: flex;
      align-items: center;
      .el-button {
        margin-left: 10px;
      }
    }
  }
  .feature-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .feature-item {
      width: 32%;
      margin-top: 10px;
      .title {
        font-size: 14px;
        margin-bottom: 6px;
        font-weight: bold;
      }
      .intro {
        color: #666;
        font-size: 12px;
      }
    }
  }

  .qrcode-wrap {
    text-align: center;
    min-height: 260px;
    .confirm {
      width: 240px;
    }
    .qrcode {
      padding: 10px;
      border: 1px solid #eee;
      background-color: #f5f5f5;
      border-radius: 4px;
    }
    .tip {
      margin-top: 5px;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #666;
      justify-content: center;
      i {
        font-size: 20px;
      }
      &.danger {
        cursor: pointer;
        &:hover {
          font-weight: bold;
        }
        i {
          font-size: 15px;
          margin-left: 4px;
        }
      }
    }
  }

  .success-wrap {
    text-align: center;
    i {
      font-size: 80px;
    }
    .gift-animation {
      height: 120px;
      width: 120px;
    }
    .tip {
      margin-top: 10px;
      font-size: 13px;
      .num {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }
  .button-wrap {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .el-button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@media (max-width: 700px) {
  .pay-content {
    padding: 10px;
    &.content-in-dialog {
      .package-wrap {
        .package-item {
          .name {
            font-size: 14px;
          }
          .price-original {
            font-size: 13px;
          }
          .price {
            font-size: 16px;
          }
        }
      }
    }
    h3 {
      font-size: 18px;
    }
    .feature-header {
      margin-top: 10px;
    }
    .package-wrap {
      flex-wrap: wrap;
      .package-item {
        width: 32%;
        max-width: 50%;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 8px;
        .label {
          position: absolute;
          width: 60px;
          top: -1px;
          right: -1px;
        }
        .name {
          font-size: 13px;
        }
        .price-original {
          font-size: 12px;
        }
        .price {
          font-size: 15px;
        }
      }
    }
    .section-footer {
      margin-top: 20px;
      flex-direction: column;
      .right {
        margin-top: 10px;
      }
    }
  }
}
</style>
