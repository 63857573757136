<template>
  <el-dialog
    class="pay-dialog-wrap"
    width="660px"
    top="10vh"
    :visible="payDialogShow"
    :close-on-click-modal="false"
    :before-close="hidePayDialog"
  >
    <Pay class="content-in-dialog" v-if="payDialogShow" />
  </el-dialog>
</template>

<script>
import Pay from "./Pay.vue"
export default {
  components: {
    Pay,
  },
  data() {
    return {}
  },
  watch: {
    "$store.state.common.payDialog": {
      handler(value) {},
      deep: true,
    },
  },
  destroyed() {},
  methods: {},
}
</script>

<style lang="scss">
@media (max-width: 700px) {
  .pay-dialog-wrap {
    .el-dialog {
      width: 95% !important;
    }
  }
}
</style>
